import backup from 'src/assets/HomePage/Backup.png'
import easyChat from 'src/assets/HomePage/EasyChat.png'
import easyDev from 'src/assets/HomePage/EasyDev.png'

export const servicesCards = [
    {
        image: backup,
        title: 'backup',
        description: 'Solução completa para Backup de Dados em Nuvem. Suporta diversas tecnologias e cargas de trabalho, tais como VMWare, Hyper-V, MySQL, SQL Server, Oracle, Microsoft 365.',
        link: '/interas/backup/',
    },
    {
        image: easyChat,
        title: 'easyChat',
        description: 'Seus clientes querem atendimento por WhatsApp, Facebook, Instagram, Telegram, e-mail e SMS tudo ao mesmo tempo? Atenda em todos de forma produtiva e organizada.',
        link: '/interas/rpa',
    },
    {
        image: easyDev,
        title: 'easyDev',
        description: 'Nossas soluções de Desenvolvimento Web são ideais para sua empresa ingressar no mundo digital. Desenvolvemos aplicativos, sites e automações para otimizar suas tarefas.',
        link: '/interas/server',
    },
]