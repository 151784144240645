import YouTube from 'react-youtube'

interface Props {
    videoId: string,
}

export default function VideosComponents({ videoId }: Props) {
    const opts = {
        height: '315',
        width: '560',
        playerVars: {
            autoplay: 1,
        },
    };

    return <YouTube videoId={videoId} opts={opts} />
}