import Logo from "../../assets/Interas25Anos.png";
import { Link } from 'react-router-dom';
import LinksFooter from "./LinksFooter";

export default function Footer() {

  return (
    <div className="flex flex-col w-full justify-center items-center px-8 lg:px-20 bg-primary-900 py-10">
      <div className='flex flex-col lg:flex-row w-full lg:w-11/12 justify-start items-start gap-y-5'>
        <div className='flex flex-col w-full lg:w-3/12 justify-center items-center lg:items-start gap-y-5'>
          <img src={Logo} width="60%" alt="" />
          <div className=' flex w-full lg:w-10/12'>
            <p className=' text-white text-center lg:text-start text-textlight font-extralight'>
              Tecnologia para Gestão e Desenvolvimento
              Copyright©2024, Interas Tecnologia.
              Todos os direitos reservados.
            </p>
          </div>
        </div>
        <LinksFooter />
      </div>
      <div className='flex w-11/12 py-10'>
        <span className=' border-t-2 border-white/10 w-full'></span>
      </div>
      <div className='flex w-full justify-center lg:justify-start items-center text-white gap-x-8 px-8 lg:px-20'>
        <Link className='flex gap-x-2 hover:underline' to="/">Termos</Link>
        <Link className='flex gap-x-2 hover:underline' to="/">Privacidade</Link>
        <Link className='flex gap-x-2 hover:underline' to="/">Política de Privacidade</Link>
      </div>
    </div>
  )
}
