import { Button } from "../ui/button";
import RapImage from "../../assets/rpa.png";
import { Link } from "react-router-dom";

export default function DevRpa() {
  return (
    <div className="h-full flex flex-col lg:flex-row bg-primary-900 items-center py-0 lg:py-16 overflow-hidden">
      {/* Image side */}
      <div className="flex lg:hidden w-full justify-center mt-20" data-aos="fade-up-left">
        <img
          className="flex lg:hidden w-auto h-80 py-8 rounded-2xl animate-[bounce_2s_ease-in-out_infinite]"
          src={RapImage}
        />
      </div>
      {/* Text side */}
      <div
        className="w-full lg:w-1/2 px-8 lg:px-0 pl-8 lg:pl-32 pb-8 lg:pb-0 space-y-6 text-center lg:text-start"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
      >
        <h1 className="text-4xl lg:text-6xl font-bold tracking-tight text-white">
          Desenvolvimento <br /> de RPAs
        </h1>
        <h1 className="text-2xl lg:text-4xl font-thin tracking-tight text-white">
          Automatize{" "}
          <span className=" text-secondary-100 text-2xl lg:text-4xl font-thin">
            processos!
          </span>
        </h1>
        <p className="text-white text-justify font-thin w-full text-lg lg:text-base">
          A maioria das ferramentas de RPA é executada em estações de trabalho
          independentes e treinada para realizar tarefas rotineiras, como mover
          linhas de um banco de dados para uma planilha. Ainda que
          individualmente os robôs se concentrem apenas em tarefas simples,
          juntos eles geram diversos benefícios. Como parte da estratégia de
          gerenciamento de processos de negócios (BPM) de uma organização, a RPA
          pode desempenhar um papel fundamental no aumento da eficiência das
          operações.
        </p>
        <Link to="/interas/rpa">
          <Button variant="default" className="font-semibold text-sm w-50 mt-5">
            Saiba mais
          </Button>
        </Link>
      </div>
      {/* Image side */}
      <div className="hidden w-1/2 lg:flex justify-center mr-20" data-aos="fade-up-left">
        <img
          className="hidden lg:flex w-auto h-80 rounded-2xl animate-[bounce_2s_ease-in-out_infinite]"
          src={RapImage}
        />
      </div>
    </div>
  );
}

