import { useEffect, useState } from 'react'
import Cover from '../../assets/machine.png'
import CoverMobile from '../../assets/machineMobile.png'

export default function OurServices() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 900)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])


    const [bgImage, setBgImage] = useState(Cover)

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth

            if (isMobile < 519) {
                setBgImage(CoverMobile)
            } else if (isMobile > 520 && isMobile < 999) {
                setBgImage(CoverMobile)
            } else {
                setBgImage(Cover)
            }
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const titlesCards = [
        {
            title: 'Redução de Custos',
            description: 'Automação de processos com redução de custos de mão de obra e tempo gasto na operação de sistemas diversos.',
        },
        {
            title: 'Visibilidade & Padronização de Processos',
            description: 'Padronização dos processos, registro e visibilidade de todas as operações executados.',
        },
        {
            title: 'Execução 24/7',
            description: 'Processos executados sem interrupções, reduzindo perdas em trocas de turnos ou problemas relacionados a ausências de profissionais.',
        },
    ]

    return (
        <div className="flex flex-col w-full h-auto justify-center items-center py-20 gap-y-10">
            <div className='flex flex-col w-full lg:w-9/12 h-auto justify-center items-start px-5 lg:px-0 gap-y-5'>
                <h3 className="text-textlight lg:text-textmin font-bold tracking-tight text-primary-900">Principais Serviços</h3>
                <img src={bgImage} alt="Cover" />
            </div>
            <div className='flex flex-wrap w-full lg:w-8/12 h-auto justify-start items-center px-5 lg:px-32'>
                {titlesCards.map((item, index) => (
                    <div key={index} className='flex flex-col w-full lg:w-6/12 h-auto p-5 justify-center items-start gap-y-2'>
                        <h3 className="text-textmin lg:text-description font-bold tracking-tight text-primary-900">{item.title}</h3>
                        <h4 className="text-textlight font-regular tracking-tight text-primary-900">{item.description}</h4>
                    </div>
                ))}
            </div>
        </div>
    )
}