import { SheetContent, SheetHeader } from "../ui/sheet";

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "../ui/accordion";

import {
	BookUser,
	BriefcaseBusiness,
	ChevronLeft,
	Handshake,
	Home,
	Users,
	X,
} from "lucide-react";
import { Link } from "react-router-dom";
import { Products } from "src/Utils/useProductsLinks";
import LogoInteras from "../../assets/Interas25Anos.png";
import { Button } from "../ui/button";
import { Separator } from "../ui/separator";

import Whatsapp from "../../assets/whatsapp.svg";

interface Props {
	handleOpenMenu: () => void;
}

export default function LinksMenuMobile({ handleOpenMenu }: Props) {
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	return (
		<SheetContent
			className="flex flex-col items-center bg-primary-900 border-none text-white"
			side="left"
		>
			<SheetHeader className="flex w-full h-14 justify-end items-start px-5">
				<div className="flex w-full h-auto justify-between">
					<img src={LogoInteras} width="70%" alt="" />
					<Button
						variant="link"
						onClick={handleOpenMenu}
						className="flex w-auto items-center justify-end bg-primary-800 rounded-full p-2 gap-x-4"
					>
						<ChevronLeft />
					</Button>
				</div>
			</SheetHeader>
			<div className="flex flex-col w-full mt-5 h-auto">
				<div className="flex flex-col mt-10 items-center justify-center text-center gap-y-6">
					<Link
						className="w-11/12 text-white bg-primary-800 text-start rounded-xl p-3"
						to="/"
						aria-current="page"
					>
						<div className="flex w-full items-center gap-x-4">
							<div className="flex border-2 border-white/10 rounded-full p-2">
								<Home size="20" />
							</div>
							<p className=" text-light font-semibold">Início</p>
						</div>
					</Link>
					<Accordion type="single" collapsible className="w-full text-white">
						<AccordionItem
							value="item-1"
							className="flex flex-col w-11/12 bg-primary-800 justify-center items-start border-transparent px-3 ml-3 rounded-xl"
						>
							<AccordionTrigger>
								<div className="flex w-full items-center gap-x-4">
									<div className="flex border-2 border-white/10 rounded-full p-2">
										<BriefcaseBusiness size="20" />
									</div>
									<p className=" text-light font-semibold">Serviços</p>
								</div>
							</AccordionTrigger>
							<AccordionContent className=" bg-primary-800">
								<Separator className="border border-white/5 mb-5" />
								{Products.map((item, index) => (
									<div
										key={index}
										className="flex flex-col w-full justify-center items-center"
									>
										<Link to={item.href} onClick={scrollToTop}>
											<div className="flex justify-center items-start gap-x-2 mb-8">
												<div className="flex border-2 border-white/10 rounded-full p-2">
													<item.icon
														className="h-6 w-6 text-white group-hover:text-indigo-600"
														aria-hidden="true"
													/>
												</div>
												<div className="flex flex-col items-start gap-y-2">
													<h2 className="text-textmin font-semibold tracking-tight text-white">
														{item.name}
													</h2>
													<h4 className="text-start text-textminlight">
														{item.description}
													</h4>
												</div>
											</div>
										</Link>
									</div>
								))}
							</AccordionContent>
						</AccordionItem>
					</Accordion>
					<Link
						className="w-11/12 text-white bg-primary-800 text-start p-3 rounded-xl"
						to="/#quemSomos"
					>
						<div className="flex w-full items-center gap-x-4">
							<div className="flex border-2 border-white/10 rounded-full p-2">
								<Users size="20" />
							</div>
							<p className=" text-light font-semibold">Quem Somos?</p>
						</div>
					</Link>
					<Link
						className="w-11/12 text-white bg-primary-800 text-start p-3 rounded-xl"
						to="/#parceiros"
					>
						<div className="flex w-full items-center gap-x-4">
							<div className="flex border-2 border-white/10 rounded-full p-2">
								<Handshake size="20" />
							</div>
							<p className=" text-light font-semibold">Parceiros</p>
						</div>
					</Link>
					<Link
						className="w-11/12 text-white bg-primary-800 text-start p-3 rounded-xl"
						to="/#contato"
					>
						<div className="flex w-full items-center gap-x-4">
							<div className="flex border-2 border-white/10 rounded-full p-2">
								<BookUser size="20" />
							</div>
							<p className=" text-light font-semibold">Contato</p>
						</div>
					</Link>
				</div>
			</div>
			<div className="flex flex-col w-11/12 h-80 justify-end gap-y-4 mb-4">
				<Link
					to="https://wa.me/558440420130"
					target="_blank"
					className="flex w-full"
				>
					<Button className="flex w-full gap-x-4">
						<img src={Whatsapp} width="30px" alt="Whatsapp" />
						<p className="text-textlight">Entrar em contato</p>
					</Button>
				</Link>
				<p className=" text-textminlight text-white/40 text-center">
					Tecnologia para Gestão e Desenvolvimento Copyright©2024, Interas
					Tecnologia. Todos os direitos reservados.
				</p>
			</div>
		</SheetContent>
	);
}
