import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "../lib/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-secondary-100 text-white hover:bg-transparent hover:text-secondary-100 hover: borderbutton",
        Submit: "bg-secondary-100 hover:bg-transparent hover:text-secondary-100 border-2 border-transparent rounded-full hover:border-2 hover:border-secondary-100",
        decorated: "hover:before:bg-secondary-100 relative h-[50px] w-[80px] overflow-hidden border border-white bg-transparent px-3 shadow-2xl transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-secondary-100 before:transition-all before:duration-500 hover:text-white hover:shadow-black hover:before:left-0 hover:before:w-full",
        destructive:
          "bg-secondary-100 text-white hover:bg-transparent hover:text-secondary-100 hover: borderbutton-cards",
        outline:
          "border border-input rounded-full bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary: "button-gradient text-white  hover:text-secondary-100 hover: borderbutton-basic",
        ghost: "hover:bg-black hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
