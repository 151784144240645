import { Link } from 'react-router-dom'
import bgContactBrityRPA from '../../assets/bg_contact.jpg'
import { Button } from '../ui/button'

export default function Contact() {
    return (
        <div
            className='flex w-full h-auto justify-center items-center py-20'
            style={{
                backgroundImage: `url(${bgContactBrityRPA})`,
                backgroundSize: 'cover',
                height: 'auto'
            }}
        >
            <div className='flex flex-col justify-center items-center px-5 lg:px-0 gap-y-5'>
                <h2 className="text-description lg:text-subtitle font-bold tracking-tight text-start text-white">Vamos conversar</h2>
                <h4 className="text-texlight font-regular tracking-tight text-center lg:text-start text-white">Caso esteja procurando uma solução comercial específica ou apenas precise tirar algumas dúvidas, estamos aqui para ajudar.</h4>
                <Link to="https://api.whatsapp.com/send?phone=558440420130&text=Ol%C3%A1,%20vim%20atrav%C3%A9s%20do%20site%20Interas,%20gostaria%20de%20conhecer%20mais%20os%20seus%20servi%C3%A7os.">
                    <Button variant="decorated" className='w-auto px-12 py-5'><span className="relative z-10 text-textlight font-regular tracking-tight text-start text-white">Entrar em contato</span></Button>
                </Link>
            </div>
        </div>
    )
}