import { useEffect, useState } from 'react';
import LogoCircleInteras from '../../assets/Logo_Circle_Interas.png'

interface Props {
    startFadeOut: boolean
}

export default function Loading({ startFadeOut }: Props) {
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        if (startFadeOut) {
            setFadeOut(true);
        }
    }, [startFadeOut]);

    return (
        <div className="flex items-center justify-center h-screen bg-primary-900">
            <img
                src={LogoCircleInteras}
                width="80px"
                alt="LogoCircleInteras"
                className={`transition-opacity duration-700 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}
            />
        </div>
    );
}