import { useEffect, useState } from "react"

import DataCentersImage from '../../assets/DataCenters.png'
import DataCentersImageMobile from '../../assets/DataCentersMobile.png'

import Brasil from '../../assets/Brasil.png'

import Ascenty from '../../assets/ascenty.png'
import Lumen from '../../assets/Lumen.png'

import check from '../../assets/check.svg'

export default function DataCenters() {

    const [bgImage, setBgImage] = useState(window.innerWidth < 519 ? DataCentersImageMobile : DataCentersImage)

    useEffect(() => {
      const handleResize = () => {
        const isMobile = window.innerWidth < 519
        setBgImage(isMobile ? DataCentersImageMobile : DataCentersImage)
      }
  
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }, [])

    const CardsDC = [
        {
            icon: check,
            description: 'Os melhores data centers da América Latina',
            cor: '#0061FE',
        },
        {
            icon: check,
            description: 'Escolhidos pelas maiores empresas de tecnologia do mundo',
            cor: '#5998FF',
        },
        {
            icon: check,
            description: 'Temos um amplo ecossistema de conectividade',
            cor: '#0061FE',
        },
        {
            icon: check,
            description: 'Atendimento de excelência para os seus clientes',
            cor: '#5998FF',
        },
    ]

    return (
        <div className="flex flex-col w-full h-auto justify-center items-center gap-y-32">
            <div className="flex flex-col w-full h-auto justify-center items-center">
                <div className="flex w-full h-80 justify-center items-center">
                    <img src={bgImage} alt="icon" />
                </div>
                <div className="flex flex-row w-full lg:w-[1250px] -mt-0 lg:-mt-14">
                    <div className="flex flex-col bg-secondary-100 gap-y-5 p-5">
                        <h1 className="text-description lg:text-title font-bold tracking-tight text-white text-start">Os melhores Data Centers</h1>
                        <h4 className="textmin font-light tracking-tight text-white">Oferecemos uma infraestrutura de qualidade e proteção para seus<br />clientes e parceiros.</h4>
                    </div>
                    <div className="flex flex-col justify-start items-start w-[270px] gap-y-5">
                        <img src={Brasil} alt="icon" />
                        <h4 className="text-textminlight lg:text-textlight font-light pl-5 tracking-tight text-primary-900">
                            Dados armazenados em<br />território nacional em<br />DataCenter TIER III.
                        </h4>
                    </div>
                </div>
            </div>
            <div className="flex lg:flex-row flex-col w-full h-auto justify-center items-center gap-x-40">
                <div className="flex flex-col justify-center items-center lg:w-[500px] w-auto lg:p-0 p-5 h-auto gap-y-10">
                    <img src={Ascenty} alt="icon" />
                    <h4 className="text-textlight lg:text-textmin font-light tracking-tight text-primary-900 text-justify">
                        Para proporcionar a segurança e a robustez necessária para os nossos parceiros, disponibilizamos
                        as maiores e mais qualificadas infraestruturas de data centers em operação no Brasil.
                        Acesse um ambiente com alta disponibilidade, com sistemas 100% redundantes e preparados
                        para operar em situações críticas.
                    </h4>
                </div>
                <div className="flex flex-col justify-center items-center lg:w-[500px] w-auto lg:p-0 p-5 h-auto gap-y-5">
                    <img src={Lumen} alt="icon" />
                    <h4 className="text-textlight lg:text-textmin font-light tracking-tight text-primary-900 text-justify">
                        Para proporcionar a segurança e a robustez necessária para os nossos parceiros, disponibilizamos
                        as maiores e mais qualificadas infraestruturas de data centers em operação no Brasil.
                        Acesse um ambiente com alta disponibilidade, com sistemas 100% redundantes e preparados
                        para operar em situações críticas.
                    </h4>
                </div>
            </div>
            <div className="flex lg:flex-row flex-col justify-center items-center w-full lg:p-10 p-0 lg:gap-x-5 gap-x-0 lg:gap-y-0 gap-y-5">
                {CardsDC.map((item, index) => (
                    <div
                        key={index}
                        className="flex flex-col w-80 p-10 h-80 justify-center items-start gap-y-5"
                        style={{ backgroundColor: item.cor }}
                    >
                        <img src={item.icon} width="40px" alt="icon" />
                        <h4 className="text-description font-light tracking-tight text-white">{item.description}</h4>
                    </div>
                ))}
            </div>
        </div>
    )
}