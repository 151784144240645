import {
    Sheet,
    SheetTrigger,

} from "../ui/sheet"

import { IoMenuSharp } from "react-icons/io5"
import { Button } from "../ui/button"
import LinksMenuMobile from "./LinksMenuMobile"
import { useState } from "react"


export default function MobileMenu() {

    const [isOpenMenu, setIsOpenMenu] = useState(false)

    const handleOpenMenu = () => {
        setIsOpenMenu(!isOpenMenu)
    }

    return (
        <div className="lg:hidden">
            <Sheet open={isOpenMenu} onOpenChange={setIsOpenMenu}>
                <SheetTrigger asChild>
                    <Button variant="link"><IoMenuSharp size="30" color="white" /></Button>
                </SheetTrigger>
                <LinksMenuMobile handleOpenMenu={handleOpenMenu} />
            </Sheet>
        </div>
    )
}