// @ts-ignore
import { useEffect, useState } from "react";

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "../ui/drawer"

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../ui/select"

import { Button } from "../ui/button";

import BackgrounCloudSimuation from "../../assets/CloudSimulationBG.png"
import BackgrounCloudSimuationMobile from "../../assets/MobileSimulationBg.png"

import centOS from '../../assets/centos.svg'
import Debian from '../../assets/debian.svg'
import Ubuntu from '../../assets/unbutu.svg'
import WinServer12 from '../../assets/winServer.svg'
import WinServer16 from '../../assets/winServer.svg'

import { Minus, Plus } from "lucide-react"

export default function CloudSimulation() {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [])

  const [vCPU, setVcpu] = useState(9)
  const [Ram, setRam] = useState(32)
  const [ssd, setSsd] = useState(256)
  const [Bandwidth, setBandwidth] = useState(10)
  const [Snapshot, setSnapshot] = useState(500)
  const [IPv4, setIPv4] = useState(10)

  const [valorCPU, setValorCPU] = useState(35.40)
  const [valorRam, setValorRam] = useState(8)
  const [valorSsd, setValorSsd] = useState(3.60)
  const [valorBand, setValorBand] = useState(40.96)
  const [valorSnap, setValorSnap] = useState(0.20)
  const [valorIPv4, setValorIPv4] = useState(20)

  const [valueRadio, setValueRadio] = useState(1)

  const [nameSyst, setNameSyst] = useState('')

  const capturedNameSystem = (selectedValue: any) => {
    setNameSyst(selectedValue)
  }

  const [somaValores, setSomaValores] = useState(0)
  const [totalValoresRadio, setTotalValoresRadio] = useState(0)
  const [totalValoresSystem, setTotalValoresSystem] = useState(0)
  const [valorTotal, setValorTotal] = useState(0)

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueRadio(parseInt(event.target.value, 10))
  }

  function adjustState(setter: React.Dispatch<React.SetStateAction<number>>, adjustment: number) {
    setter(prevState => Math.max(1, Math.min(1000, prevState + adjustment)))
  }


  // ajuste de preço das configurações
  useEffect(() => {
    setValorCPU(vCPU * 35.40)
    setValorRam(Ram * 8)
    setValorSsd(ssd * 0.60)
    setValorBand(Bandwidth * 40.96)
    setValorSnap(Snapshot * 0.20)
    setValorIPv4(IPv4 * 20)

  }, [vCPU, Ram, ssd, Bandwidth, Snapshot, IPv4])

  // soma dos valores das configurações
  useEffect(() => {
    setSomaValores(
      valorCPU +
      valorRam +
      valorSsd +
      valorBand +
      valorSnap +
      valorIPv4)

  }, [valorCPU, valorRam, valorSsd, valorBand, valorSnap, valorIPv4])

  // soma dos valores incluindo painel de controle
  useEffect(() => {
    if (valueRadio === 2) {
      setTotalValoresRadio(71.39)
    } else if (valueRadio === 3) {
      setTotalValoresRadio(245.4)
    } else if (valueRadio === 4) {
      setTotalValoresRadio(56.4)
    } else {
      setTotalValoresRadio(0)
    }
  }, [valueRadio])

  // soma dos valores incluindo sistema operacionais
  useEffect(() => {
    if (nameSyst === 'Windows Server 2012') {
      setTotalValoresSystem(108)
    } else if (nameSyst === 'Windows Server 2016') {
      setTotalValoresSystem(108)
    } else {
      setTotalValoresSystem(0)
    }
  })

  // soma total
  useEffect(() => {
    setValorTotal(somaValores + totalValoresRadio + totalValoresSystem)
  })

  const pacoteServer = [
    {
      title: 'vCPU',
      quantidade: vCPU,
      maxQuant: 16,
      atualizarValor: setVcpu,
      valor: valorCPU,
      rotulo: '',
    },
    {
      title: 'RAM',
      quantidade: Ram,
      maxQuant: 64,
      atualizarValor: setRam,
      valor: valorRam,
      rotulo: 'GB',
    },
    {
      title: 'SSD',
      quantidade: ssd,
      maxQuant: 512,
      atualizarValor: setSsd,
      valor: valorSsd,
      rotulo: 'GB',
    },
    {
      title: 'Bandwidth',
      quantidade: Bandwidth,
      maxQuant: 100,
      atualizarValor: setBandwidth,
      valor: valorBand,
      rotulo: 'TB',
    },
    {
      title: 'Backup Snapshot',
      quantidade: Snapshot,
      maxQuant: 1000,
      atualizarValor: setSnapshot,
      valor: valorSnap,
      rotulo: 'GB',
    },
    {
      title: 'IPv4',
      quantidade: IPv4,
      maxQuant: 100,
      atualizarValor: setIPv4,
      valor: valorIPv4,
      rotulo: '',
    },
  ]

  const SistemasOperacionais = [
    {
      icon: centOS,
      name: 'centOS',
    },
    {
      icon: Debian,
      name: 'Debian',
    },
    {
      icon: Ubuntu,
      name: 'Ubuntu',
    },
    {
      icon: WinServer12,
      name: 'Windows Server 2012',
    },
    {
      icon: WinServer16,
      name: 'Windows Server 2016',
    },
  ]

  return (
    <div
      id="cloud-services"
      className="w-full h-[20rem] lg:h-full lg:p-20 p-8 gap-10 flex flex-col items-center justify-center"
      style={{
        backgroundImage: `url(${isMobile ? BackgrounCloudSimuationMobile : BackgrounCloudSimuation})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: isMobile ? "70vh" : "100vh",
      }}
    >
      <h1
        data-aos="fade-up"
        data-aos-duration="1500"
        className="text-subtitle lg:text-title text-center font-bold text-secondary-100  w-full  lg:w-1/2"
      >
        Faça agora uma simulação
      </h1>
      <Drawer>
        <DrawerTrigger asChild>
          <Button data-aos="flip-down" data-aos-duration="1500" variant="default" className="font-semibold text-xl lg:text-2xl lg:h-20 lg:w-[30rem]">
            Clique aqui para simular
          </Button>
        </DrawerTrigger>
        <DrawerContent className="bg-white">
          <div className="flex flex-col w-full justify-center items-center">

            <DrawerHeader className="flex flex-col justify-center items-center">
              <DrawerTitle><h2 className="subtitle font-bold tracking-tight text-primary-900">Simulador</h2></DrawerTitle>
              <DrawerDescription><h3 className="textmin font-light tracking-tight text-center text-primary-900">Escolha a melhor configuração para você</h3></DrawerDescription>
            </DrawerHeader>

            {/* select */}
            <div className="flex w-full h-auto justify-center items-center">
              <Select value={nameSyst} onValueChange={capturedNameSystem}>
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Escolha um sistema" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup className="bg-white">
                    <SelectLabel>Sistemas</SelectLabel>
                    {SistemasOperacionais.map((item, index) => (
                      <SelectItem
                        className="flex w-auto justify-start items-center"
                        key={index}
                        value={item.name}
                      >
                        <div className="flex flex-row w-auto justify-start items-center gap-x-2">
                          <h4>{item.name}</h4>
                          <img src={item.icon} alt="icon" />
                        </div>
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            {/* coluna 1 da simulação */}
            <div className="flex flex-wrap lg:w-[800px] w-[500px] justify-center items-center p-4 pb-0 gap-x-10">
              {pacoteServer.map((item, index) => (
                <div key={index} className="flex flex-row items-center justify-center space-x-2">
                  <Button
                    variant="outline"
                    size="icon"
                    className="h-8 w-8 shrink-0 rounded-full"
                    onClick={() => adjustState(item.atualizarValor, -1)}
                    disabled={item.quantidade <= 1}
                  >
                    <Minus className="h-4 w-4" />
                    <span className="sr-only">Decrease</span>
                  </Button>
                  <div className="flex-1 text-center">
                    <div className="text-[0.70rem] uppercase text-muted-foreground">
                      {item.title}
                    </div>
                    <div className="flex flex-row text-7xl justify-center items-center font-bold tracking-tighter">
                      <h1 className="text-subtitle lg:text-title font-bold tracking-tight text-center text-primary-900">{item.quantidade}</h1>
                      <h3 className="text-subtitle font-bold tracking-tight text-center text-primary-900">{item.rotulo}</h3>
                    </div>
                    <div className="text-[0.70rem] uppercase text-muted-foreground mb-5">
                      {item.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 })}
                    </div>
                  </div>
                  <Button
                    variant="outline"
                    size="icon"
                    className="h-8 w-8 shrink-0 rounded-full"
                    onClick={() => adjustState(item.atualizarValor, 1)}
                    disabled={item.quantidade >= item.maxQuant}
                  >
                    <Plus className="h-4 w-4" />
                    <span className="sr-only">Increase</span>
                  </Button>
                </div>
              ))}
            </div>
            {/* selecionar paineis */}
            <div className="flex flex-col w-full h- justify-center items-center gap-y-5">
              <h2 className="description font-bold tracking-tight text-center text-primary-900">Escolha um painel</h2>
              <div className="flex flex-row justify-center items-center w-full gap-x-2">
                <input
                  type="radio"
                  id="r1"
                  value={1}
                  checked={valueRadio === 1}
                  onChange={handleRadioChange}
                />
                <h4 className="textlight font-light tracking-tight text-center text-primary-900">Sem o painel de controle</h4>
                <input
                  type="radio"
                  id="r2"
                  value={2}
                  checked={valueRadio === 2}
                  onChange={handleRadioChange}
                />
                <h4 className="textlight font-light tracking-tight text-center text-primary-900">cPainel - Solo (1 conta)</h4>
                <input
                  type="radio"
                  id="r3"
                  value={3}
                  checked={valueRadio === 3}
                  onChange={handleRadioChange}
                />
                <h4 className="textlight font-light tracking-tight text-center text-primary-900">cPainel - premier (Contas Ilimitadas)</h4>
                <input
                  type="radio"
                  id="r4"
                  value={4}
                  checked={valueRadio === 4}
                  onChange={handleRadioChange}
                />
                <h4 className="textlight font-light tracking-tight text-center text-primary-900">Plesk</h4>
              </div>
            </div>
            {/* valor total */}
            <div>
              <h2 className="text-description font-bold tracking-tight text-center text-primary-900">Valor Total:</h2>
              <h1 className="text-subtitle font-bold tracking-tight text-center text-primary-900">{valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 })}</h1>
            </div>
            <DrawerFooter>
              <DrawerClose asChild>
                <Button className="w-80" variant="outline">Sair</Button>
              </DrawerClose>
            </DrawerFooter>
          </div>
        </DrawerContent>
      </Drawer>
    </div >
  );
}
