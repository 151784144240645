import { Button } from "../ui/button"
import BackgrounServer from "../../assets/BackgroundIntroServerPage.png"
import BackgrounServerMobile from "../../assets/BakcgroundIntroServerMobile.png"
import SafeComputer from "../../assets/ComputerSecurity.png"
import { FaArrowDown, FaArrowRight } from "react-icons/fa6"

export default function IntroServerPage() {

  const isMobile = window.innerWidth <= 768

  const corPersonalizada = "#0061FE"

  return (
    <div
      className="
        font-poppinsw-full 
        flex 
        lg:flex-row 
        flex-col
        lg:justify-evenly 
        justify-center
        items-center 
        transition-all  
        overflow-hidden
        "
      style={{
        backgroundImage: `url(${isMobile ? BackgrounServerMobile : BackgrounServer
          })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: isMobile ? "90vh" : "100vh",
      }}
    >
      <div className="flex flex-col justify-center lg:items-start items-center p-5 lg:h-full h-auto lg:gap-y-10 gap-y-10">
        <h1
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="text-subtitle lg:text-title font-bold tracking-tight text-white"
        >
          CloudServer
        </h1>
        <h2
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="text-[2rem] font-medium tracking-tight text-white hidden lg:block"
        >
          Soluções <span style={{ color: corPersonalizada }}>Definitiva!</span>
        </h2>
        <h4
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="textmin w-full font-light tracking-tight text-white hidden lg:block"
        >
          Aqui você encontra em forma de solução: hardwares <br />
          fisicamente isolados, de uso exclusivo da sua empresa. <br />
          Mais segurança e desempenho com monitoramento <br />
          proativo 24h por dia.
        </h4>
        <h4
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="textlight font-light tracking-tight text-white text-justify lg:hidden"
        >
          Aqui você encontra em forma de solução: hardwares fisicamente
          isolados, de uso exclusivo da sua empresa. Mais segurança e desempenho
          com monitoramento proativo 24h por dia.
        </h4>
        <div className="flex flex-col lg:flex-row w-full justify-start items-center lg:gap-x-5 gap-y-5">
          <a href="#cloudservices">
            <Button
              variant="default"
              className="font-semibold text-sm lg:text-[18px]"
            >
              CONHEÇA NOSSAS SOLUÇÕES
            </Button>
          </a>
          <FaArrowRight
            size="30px"
            color="#0061FE"
            className="hidden lg:block"
          />
          <FaArrowDown size="30px" color="#0061FE" className="lg:hidden" />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div
          data-aos="fade-left"
          data-aos-delay="500"
          data-aos-offset="0"
          className="flex justify-center items-center lg:-mr-32 -mr-0"
        >
          <img
            src={SafeComputer}
            alt="Sticker"
            width='80%'
          />
        </div>
      </div>
    </div>
  );
}
