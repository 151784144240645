import { Cloud, Code, Server } from "lucide-react";

export const Products = [
	{
		name: "Cloud Backup",
		description:
			"Hospede seus dados na nuvem com maior praticidade e segurança.",
		href: "/backup/",
		icon: Cloud,
	},
	{
		name: "EasyDev",
		description:
			"Soluções de Desenvolvimento ideais para sua empresa ingressar no mundo digital.",
		href: "/dev",
		icon: Code,
	},
	{
		name: "Cloud Server",
		description:
			"Hospede com maior segurança os servidores da sua empresa em núvem.",
		href: "/server",
		icon: Server,
	},
];
