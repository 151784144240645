export default function Functionalities() {

    const CardsFunctionalities = [
        {
            title: 'Backup Completo',
            description: 'Os dados são armazenados em datacenters certificados, localizados no Brasil e atendendo as máximas exigências de segurança e auditoria.',
        },
        {
            title: 'Backup Incremental',
            description: 'Após o backup completo, tem a capacidade de identificar e realizar o backup apenas dos arquivos novos e modificados.',
        },
        {
            title: 'Backup Delta',
            description: 'Capacidade de enviar apenas os blocos modificados de um arquivo sem realizar o seu upload a cada nova alteração.',
        },
        {
            title: 'Backup Incremental',
            description: 'Após o backup completo, tem a capacidade de identificar e realizar o backup apenas dos arquivos novos e modificados.',
        },
        {
            title: 'Backup de arquivos abertos',
            description: 'Após o backup completo, tem a capacidade de identificar e realizar o backup apenas dos arquivos novos e modificados.',
        },
        {
            title: 'Backup Local',
            description: 'Permite que seja mantida uma cópia local dos seus backups para maior agilidade em suas restaurações.',
        },
        {
            title: 'Backup de unidade de rede',
            description: 'Permite a seleção de compartilhamentos de rede como origem do backup: NAS, servidores standalone, CIFS, entre outros.',
        },
        {
            title: 'Backup Agendado',
            description: 'Permite a criação de múltiplos agendamentos do tipo: diário, semanal, mensal, anual ou políticas personalizadas.',
        },
        {
            title: 'Segurança',
            description: 'Os seus dados estarão armazenados em nossos data centers World Class Tier III em território nacional.',
        },
        {
            title: 'Velocidade GBSP',
            description: 'Backbone com capacidade Gbps de banda internet permitindo altíssimas taxas de transferências de dados.',
        },
        {
            title: 'Envio de HD Externo',
            description: 'Possibilidade de realizar o primeiro backup (Full) por meio do envio de um hd externo criptografado.',
        },
        {
            title: 'Compressão dos Dados',
            description: 'Realiza a compressão dos dados em taxas de até 80% para a otimização do consumo de espaço em nuvem.',
        },
        {
            title: 'Transferência SSL',
            description: 'Os dados são transferidos por meio de conexões da internet utilizando um canal seguro de comunicação (https/SSL).',
        },
        {
            title: 'Restauração Granular',
            description: 'Possibilidade de realizar a restauração completa ou granular de um arquivo ou até mesmo uma tabela de banco de dados.',
        },
        {
            title: 'Controle de Banda',
            description: 'Permite limitar o consumo máximo da banda internet disponível para a aplicação de backup.',
        },
        {
            title: 'Retenção',
            description: 'Permite realizar a restauração de um arquivo em uma data específica de dias, semanas, meses ou anos atrás.',
        },
        {
            title: 'Versionamento',
            description: 'Permite a realização do download de versões de dias anteriores dos arquivos modificados ou excluídos.',
        },
        {
            title: 'Office 365',
            description: 'Realize backup dos E-mails, Sharepoint e arquivos do Microsoft Teams hospedados no Office 365.',
        },
        {
            title: 'Pré Comando',
            description: 'Permite a execução de um comando do sistema operacional antes e após a execução do backup.',
        },
        {
            title: 'Administração Remota',
            description: 'Permite que os agentes de backup sejam configurados remotamente nos servidores do cliente por meio do portal web.',
        },
        {
            title: 'Microsoft Windows Server',
            description: 'Realiza backup de sistemas operacionais Microsoft Windows 2000, XP, Vista, 7, 8, 10, 11 e todas as plataformas Windows Server',
        },
        {
            title: 'Linux/Unix',
            description: 'Realiza backup de sistemas operacionais Linux e Unix com opções de execução por meio de linha de comando.',
        },
        {
            title: 'Oracle',
            description: 'Plugin específico para realização de backup de banco de dados Oracle.',
        },
        {
            title: 'SQL Server',
            description: 'Plugin específico para realização de backup de banco de dados MS SQL Server.',
        },
        {
            title: 'MYSQL',
            description: 'Plugin específico para realização de backup de banco de dados MySQL.',
        },
        {
            title: 'FIREBIRD',
            description: 'Realiza backup de banco de dados Firebird por meio de scripts para extração de dump e backup incremental.',
        },
        {
            title: 'POSTGRESQL',
            description: 'Realiza backup de banco de dados Postgre SQL por meio de scripts para extração de dump e backup incremental.',
        },
        {
            title: 'Backup do Outlook (PST)',
            description: 'Permite que você realize backup incremental de arquivos PST do Microsoft Outlook.',
        },
        {
            title: 'VMWARE',
            description: 'Plugin específico para backup incremental de máquinas virtuais VMware/ESXi permitindo a restauração granular de seus componentes.',
        },
        {
            title: 'MICROSOFT HYPER-V',
            description: 'Plugin para backup incremental de máquinas virtuais MS Hyper-V permitindo a restauração granular de seus componentes.',
        },
        {
            title: 'SYSTEM STATE',
            description: 'Realiza o backup do estado do sistema operacional MS Windows Server permitindo a restauração pontual dos seus registros.',
        },
        {
            title: 'Relatórios de Auditoria',
            description: 'Emissão diária, semanal ou mensal de relatórios consolidados de gerenciamento e auditoria dos backups, notificação por e-mail ou aplicativo mobile.',
        },
        {
            title: 'Promox',
            description: 'Backup on-line com captura instantânea de VM Proxmox a quente ou parada, backup de imagem completo.',
        },
    ]

    return (
        <div className="flex flex-col w-full h-auto lg:p-10 p-3 justify-center items-center gap-y-5">
            <h1 className="text-description lg:text-title font-bold tracking-tight text-primary-900 text-center mb-10 lg:mb-32 mt-10 lg:mt-0">O EasyBackup e suas<br />diversas funcionalidades</h1>
            {CardsFunctionalities.map((item, index) => (
                <div key={index} className="flex lg:flex-row flex-col w-full h-auto justify-center items-center gap-x-5">
                    <div className="flex lg:w-[350px] w-full h-32 bg-secondary-100 justify-start p-5 items-center">
                        <h2 className="text-description font-medium tracking-tight text-white leading-[36px]">{item.title}</h2>
                    </div>
                    <div className="flex w-full lg:h-32 h-auto bg-secondary-50 justify-start p-5 items-center">
                        <h4 className="text-textmin font-light tracking-tight text-white">{item.description}</h4>
                    </div>
                </div>
            ))}
        </div>
    )
}