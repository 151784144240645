import { useEffect, useState, useRef } from "react"

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

import bestCloud from '../../assets/BestCloudBg.png'
import bestCloudMobile from '../../assets/BestCloudBgMobile.png'

import OneDrive from '../../assets/oneDrive.png'
import GoogleDrive from '../../assets/googleDrive.png'
import Azure from '../../assets/Azure.png'
import Alibaba from '../../assets/Alibaba.png'
import OpenStack from '../../assets/OpenStack.png'
import Rackspace from '../../assets/RackSpace.png'
import GoogleCloudStorage from '../../assets/GoogleStorage.png'
import AmazonDrive from '../../assets/AmazonDrive.png'
import eCloud from '../../assets/eCloud.png'
import DropBox from '../../assets/dropBox.png'
import AmazonWebServices from '../../assets/AmazonServices.png'
import LocalNetWork from '../../assets/LocalNetWork.png'

export default function BestClouds() {

    const splideRef = useRef<Splide | null>(null)

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 900)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const [bgImage, setBgImage] = useState(bestCloud)

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth

            if (isMobile < 519) {
                setBgImage(bestCloudMobile)
            } else {
                setBgImage(bestCloud)
            }
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const corPersonalizada = "#0061FE"

    const ImagesLogosCloud = [
        {
            image: OneDrive,
        },
        {
            image: GoogleDrive,
        },
        {
            image: Azure,
        },
        {
            image: Alibaba,
        },
        {
            image: OpenStack,
        },
        {
            image: Rackspace,
        },
        {
            image: GoogleCloudStorage,
        },
        {
            image: AmazonDrive,
        },
        {
            image: eCloud,
        },
        {
            image: DropBox,
        },
        {
            image: AmazonWebServices,
        },
        {
            image: LocalNetWork,
        },
    ]

    return (
        <div
            className="flex flex-col w-full h-auto justify-center items-center gap-y-20 overflow-hidden"
            style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', height: isMobile ? '90vh' : '160vh' }}
        >
            <div className="flex flex-col w-full justify-center items-center gap-y-5">
                <h1 className="text-subtitle lg:text-title font-bold tracking-tight text-white text-center mt-20 lg:mt-0">As melhores nuvens<br />do mercado</h1>
                <h2 className="text-description font-medium tracking-tight text-white text-center">Escolha a <span style={{ color: corPersonalizada }}>nuvem</span> de <span style={{ color: corPersonalizada }}>armazenamento</span><br />de sua <span style={{ color: corPersonalizada }}>preferência</span></h2>
            </div>
            <div className="hidden lg:flex flex-wrap w-full max-w-[1200px] h-auto justify-center items-center gap-10">
                {ImagesLogosCloud.map((item, index) => (
                    <div key={index} className="flex bg-white w-60 h-32 justify-center items-center  rounded-xl p-5">
                        <img src={item.image} width="75%" alt="logos" />
                    </div>
                ))}
            </div>
            <Splide
                ref={splideRef}
                options={{
                    height: 'auto',
                    perPage: 1,
                    type: 'loop',
                    drag: true,
                    autoplay: true,
                    arrow: true,
                    pagination: true,
                }}
                className="custom-splide custom-arrow lg:pt-0 pt-10 lg:pb-0 pb-10 lg:hidden"
            >
                {ImagesLogosCloud.map((item, index) => (
                    <SplideSlide
                        key={index}
                        className="flex flex-row w-full h-auto justify-center items-center"
                    >
                        <div className="flex bg-white w-60 h-32 justify-center items-center  rounded-xl p-5">
                            <img src={item.image} width="75%" alt="logos" />
                        </div>
                    </SplideSlide>
                ))}
            </Splide>
        </div>
    )
}