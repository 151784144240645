import { BrowserRouter, Route, Routes } from "react-router-dom";
import { BackupPage } from "./App/BackupPage/page";
import BrityRPA from "./App/BrityRPA/page";
import DevMainPage from "./App/DevPage/page"
import HomePage from "./App/HomePage/page";
import ServerPage from "./App/ServerPage/page";
import Footer from './components/Footer';
import Head from "./components/Head/index";

import { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import Loading from "./components/PageLoading";
import { Toaster } from "./components/ui/toaster";

export default function App() {

  const [isLoading, setIsLoading] = useState(true);
  const [animateOut, setAnimateOut] = useState(false);
  const [startFadeOut, setStartFadeOut] = useState(false);

  useEffect(() => {
    const fadeOutTimer = setTimeout(() => {
      setStartFadeOut(true);
    }, 1100)

    const slideOutTimer = setTimeout(() => {
      setAnimateOut(true)
    }, 1600)

    const removeLoadingTimer = setTimeout(() => {
      setIsLoading(false)
    }, 2100);

    return () => {
      clearTimeout(fadeOutTimer)
      clearTimeout(slideOutTimer)
      clearTimeout(removeLoadingTimer)
    };
  }, []);

  return (
    <>
      {isLoading && (
        <div className={`absolute inset-0 ${animateOut ? 'animate-slide-up' : ''}`}>
          <Loading startFadeOut={startFadeOut} />
        </div>
      )}
      {!isLoading && (
        <BrowserRouter>
          <Head />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/dev" element={<DevMainPage />} />
            <Route path="/backup" element={<BackupPage />} />
            <Route path="/server" element={<ServerPage />} />
            <Route path="/rpa" element={<BrityRPA />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      )}
      <div className="flex bg-green-700 text-white fixed w-16 h-16 rounded-full right-5 bottom-10 justify-center items-center p-2 duration-300 cursor-pointer hover:rotate-360 hover:bg-transparent hover:text-green-700 hover:scale-125">
        <a href="https://wa.me/558440420130" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp size="45px" />
        </a>
      </div>
      <Toaster />
    </>
  );
}