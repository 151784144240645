import Home from "src/components/HomePage/Cover"
import AboutUs from "src/components/HomePage/AboutUs"
import Solutions from "src/components/HomePage/Solutions"
import Services from "src/components/HomePage/Services"
import CaseSucesso from "src/components/HomePage/Case"
import Contato from "src/components/Contato"

import AOS from 'aos'
import 'aos/dist/aos.css'
import { useState } from "react"
import { AriseButton } from "src/Utils/useAriseButton"
import { Button } from "src/components/ui/button"
import { FaArrowUp } from "react-icons/fa";
import Loading from "src/components/PageLoading"

AOS.init();

function HomePage() {

  const [showButton, setShowButton] = useState(false)

  AriseButton(
    { setShowButton: setShowButton, id: 'services' }
  )

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <Home />
      <AboutUs />
      <Solutions />
      <Services />
      <CaseSucesso />
      <Contato />
      {showButton && (
        <Button
          variant="secondary"
          className="flex w-10 h-10 fixed right-6 xl:left-10 bottom-32 xl:bottom-10 justify-center items-center rounded-full z-30 p-0 hover:scale-110"
          onClick={scrollToTop}
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-duration="800"
          data-aos-offset="0"
        >
          <FaArrowUp />
        </Button>
      )}
    </>
  )
}

export default HomePage
