import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { useEffect, useRef, useState } from "react";

import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";


import Automation from '../../assets/Automacao.jpg'
import Implementation from '../../assets/Implementacao.jpg'

import { Button } from '../ui/button'
import { cn } from "../lib/utils"
import { Slider } from "../ui/slider"

type SliderProps = React.ComponentProps<typeof Slider>

export default function UseCase({ className, ...props }: SliderProps) {

    const splideRef = useRef<Splide | null>(null)

    const [activeButton, setActiveButton] = useState(1)
    const [sliderPosition, setSliderPosition] = useState(50)
    const [sliderValue, setSliderValue] = useState(1)

    const nextSlide = () => {
        if (splideRef.current) {
            splideRef.current.go('+1')
            setActiveButton(1)
            setSliderPosition(50)
            setSliderValue(1)
        }
    }

    const prevSlide = () => {
        if (splideRef.current) {
            splideRef.current.go('-1')
            setActiveButton(2)
            setSliderPosition(100)
            setSliderValue(2)
        }
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 900)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const carrosselItens = [
        {
            image: Automation,
            title: 'Automação de tarefas simples e repetitivas que são constantemente necessárias',
            description: 'Algumas empresas precisam realizar continuamente várias tarefas simples e repetitivas, preparar relatórios de desempenho e coletar diversos dados durante um período definido. Essas tarefas serão implementadas automaticamente, permitindo um uso mais eficiente da mão de obra.',
            link: '',
        },
        {
            image: Implementation,
            title: 'Implementação de tarefas complicadas com instruções simples via chatbot',
            description: 'Assim que um gerente solicitar as informações necessárias em linguagem natural, o Brity Works pesquisará dados e criará um relatório com base neles. Quando o trabalho estiver concluído, o gerente será avisado, recebendo uma mensagem com as informações de acompanhamento da tarefa.',
            link: '',
        },
    ]

    return (
        <div className="flex flex-col lg:flex-row h-full py-20">
            <div className="flex flex-col h-full justify-between items-center gap-y-80 mr-0 lg:mr-10">
                <div className="flex flex-row lg:flex-col h-full items-center lg:items-start">
                    <div className=" border-t-2 border-black w-full py-3">
                        <Button variant="link" className="px-2" onClick={nextSlide}>
                            <p
                                className={`
                            ${activeButton === 1
                                        ? 'text-textlight font-semibold tracking-tight text-start text-primary-900'
                                        : 'text-textlight font-semibold tracking-tight text-start text-gray-400'}
                            `}>
                                Automação de tarefas<br />
                                simples e repetitivas...
                            </p>
                        </Button>
                    </div>
                    <div className=" border-t-2 border-b-2 border-gray-200 py-3">
                        <Button variant="link" className="px-2" onClick={prevSlide}>
                            <p
                                className={`
                                    ${activeButton === 2
                                        ? 'text-textlight font-semibold tracking-tight text-start text-primary-900'
                                        : 'text-textlight font-semibold tracking-tight text-start text-gray-400'}
                                        `}>
                                Implementação de tarefas<br />
                                complicadas com instruções...
                            </p>
                        </Button>
                    </div>
                </div>
                <div className="hidden lg:flex flex-col w-full justify-end items-end gap-x-5">
                    <div className="flex w-5/12 h-8 pl-1 justify-center items-center gap-x-2">
                        <p className="text-textminlight font-semibold tracking-tight text-start text-black">0{sliderValue}</p>
                        <Slider
                            defaultValue={[50]}
                            value={[sliderPosition]}
                            max={100}
                            step={1}
                            className={cn("w-full bg-gray-300", className)}
                            {...props}
                        />
                        <p className="text-textminlight font-semibold tracking-tight text-start text-black">02</p>
                    </div>
                    <div className="flex w-full gap-x-10 justify-end items-end">
                        <Button className="p-0 rounded-full border-black h-8 w-8" variant="decorated" onClick={nextSlide}><span className="relative z-10"><IoIosArrowBack /></span></Button>
                        <Button className="p-0 rounded-full border-black h-8 w-8" variant="decorated" onClick={prevSlide}><span className="relative z-10"><IoIosArrowForward /></span></Button>
                    </div>
                </div>
            </div>
            <Splide
                ref={splideRef}
                options={{
                    type: 'fade',
                    rewind: true,
                    height: 'auto',
                    perPage: 1,
                    drag: isMobile ? false : true,
                    wheel: false,
                    autoplay: false,
                    Button: false,
                    arrow: false,
                    pagination: false,
                }}
                className="custom-splide lg:pt-0 pt-10 lg:pb-0 pb-10"
            >
                {carrosselItens.map((item, index) => (
                    <SplideSlide
                        className="flex flex-row w-full h-auto justify-center items-center"
                        key={index}
                    >
                        <div className="flex flex-col lg:flex-row gap-x-10">
                            <div className="flex justify-center items-center">
                                <img width="560px" className="-mr-0 lg:-mr-10 z-30" src={item.image} alt="carrossel" />
                            </div>
                            <div className="flex flex-col relative w-full lg:w-7/12 h-auto lg:h-[70vh] bg-gray-100 py-20 lg:py-0 px-8 lg:px-32 -ml-0 lg:-ml-28 gap-y-5 justify-center items-start ">
                                <div className="overflow-hidden">
                                    <h2 data-aos="fade-up" className="text-textlight lg:text-description font-bold tracking-tight text-start text-primary-900">{item.title}</h2>
                                </div>
                                <div className="overflow-hidden">
                                    <h4 data-aos="fade-down" className="text-texlight font-regular tracking-tight text-start text-primary-900">{item.description}</h4>
                                </div>
                                <a href="https://image.samsungsds.com/la/ai-rpa/use-cases/__icsFiles/afieldfile/2018/09/19/Brity-Works-Use-Case-04-Eng-B2B-AI-Chatbot-RPA.pdf?queryString=20231214021124" target="_blank">
                                    <Button variant="link" className="flex justify-center items-center p-0 gap-x-2 text-white decoration-transparent">
                                        <p className="text-textlight font-semibold tracking-tight text-start text-secondary-100">Fazer download</p>
                                        <IoIosArrowForward size={15} className="text-secondary-100 mt-1" />
                                    </Button>
                                </a>
                            </div>
                        </div>
                    </SplideSlide>
                ))}
            </Splide>
            <div className="flex lg:hidden w-full h-8 p-3 justify-center items-center gap-x-2">
                <p className="text-textminlight font-semibold tracking-tight text-start text-black">0{sliderValue}</p>
                <p className="text-textminlight font-semibold tracking-tight text-start text-gray-300">02</p>
                <Slider
                    defaultValue={[50]}
                    value={[sliderPosition]}
                    max={100}
                    step={1}
                    className={cn("w-full bg-gray-300", className)}
                    {...props}
                />
            </div>
        </div>
    )
}