import { Link } from "react-router-dom";
import { DropdownMenu, DropdownMenuTrigger } from "../ui/dropdown-menu";

import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import LinksMenu from "./LinksMenu";

export default function FunctionMenu() {
	const [openMenu, setOpenMenu] = useState(false);

	const handleIsOpenMenu = () => {
		setOpenMenu(!openMenu);
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	return (
		<div className="flex w-full h-auto justify-center items-center gap-x-10">
			<Link
				to="/"
				onClick={scrollToTop}
				className="text-white font-semibold hover:text-secondary-100 underlineEffect"
				aria-current="page"
			>
				Início
			</Link>
			<DropdownMenu open={openMenu} onOpenChange={setOpenMenu}>
				<DropdownMenuTrigger className="flex flex-row gap-2 justify-center items-center">
					<Link
						className="text-white font-semibold hover:text-secondary-100 underlineEffect"
						to="#"
					>
						Serviços
					</Link>
					{openMenu === true ? (
						<IoIosArrowUp color="white" />
					) : (
						<IoIosArrowDown color="white" />
					)}
				</DropdownMenuTrigger>
				<LinksMenu handleIsOpenMenu={handleIsOpenMenu} />
			</DropdownMenu>
			<Link
				className="text-white font-semibold hover:text-secondary-100 underlineEffect"
				onClick={scrollToTop}
				to="/#quemSomos"
			>
				Quem somos?
			</Link>
			<Link
				className="text-white font-semibold hover:text-secondary-100 underlineEffect"
				onClick={scrollToTop}
				to="/#parceiros"
			>
				Parceiros
			</Link>
			<Link
				className="text-white font-semibold hover:text-secondary-100 underlineEffect"
				onClick={scrollToTop}
				to="/#contato"
			>
				Contato
			</Link>
		</div>
	);
}
