import { IoIosArrowForward } from "react-icons/io";

import BrityAssistant from '../../assets/celularSamsung.png'
import BrityMessenger from '../../assets/BrityMessenger.png'
import BrityMail from '../../assets/BrityMail.png'
import BrityMeeting from '../../assets/BrityMeeting.png'
import BrityDrive from '../../assets/BrityDrive.png'
import { Button } from '../ui/button'

export default function RelatedSolutions() {

    const cardsSolutions = [
        {
            image: BrityAssistant,
            title: 'BrityAssistant',
            description: 'O Brity Assistant é uma solução para a implementação de chatbots de IA. Os chatbots de IA ganham conhecimentos específicos do setor, entendem linguagem natural, oferecem respostas ideais a perguntas e realizam tarefas conforme sejam solicitadas. O Brity Assistant é especializado em processos empresariais, de modo que você possa criar forças de trabalho digitais com facilidade.',
        },
        {
            image: BrityMessenger,
            title: 'BrityMessenger',
            description: 'Obtenha um recurso de mensagens instantâneas e protegidas otimizado para seu negócio. Esteja você no escritório ou em deslocamento, o Brity Messenger oferece a mesma experiência de usuário em ambientes Web e móveis para gerar uma colaboração eficaz entre a sua equipe.',
        },
        {
            image: BrityMail,
            title: 'BrityMail',
            description: 'Acesse com segurança todos os principais recursos de negócios, como e-mail, aprovações, agendamentos e comunidades, com o Brity Mail. Aumente a produtividade com uma experiência do usuário uniforme em vários tipos de dispositivo e acesso a vários recursos principais com apenas um clique.',
        },
        {
            image: BrityMeeting,
            title: 'BrityMeeting',
            description: 'Colabore de maneira eficaz com sua equipe a qualquer hora e em qualquer lugar. O Brity Meeting oferece vários modos de reunião e recursos interativos que geram reuniões dinâmicas em ambientes não presenciais.',
        },
        {
            image: BrityDrive,
            title: 'BrityDrive',
            description: 'Faça download, upload e compartilhe documentos em um espaço de armazenamento centralizado; monitore várias versões de documentos e assuma o controle do acesso dos usuários no Brity Drive. Ele também permite colaborações com parceiros externos sem risco de vazamento de informações.',
        },
    ]

    return (
        <div className='flex flex-col w-full h-auto justify-center items-center gap-y-10 py-20'>
            <div className='flex flex-col w-12/12 lg:w-10/12 justify-start items-start px-10'>
                <p className="text-textmin lg:text-description font-semibold tracking-tight text-start text-primary-900">Soluções relacionadas</p>
                <h2 className="text-description lg:text-subtitle font-bold tracking-tight text-start text-primary-900">Saiba mais sobre nossas ofertas</h2>
            </div>
            <div className='flex flex-wrap w-full h-auto justify-start items-center gap-y-2 px-0 lg:px-32'>
                {cardsSolutions.map((item, index) => (
                    <div
                        key={index}
                        className='flex flex-col w-full lg:w-4/12 h-[75vh] justify-start items-start px-10 gap-y-2'
                    >
                        <div className="overflow-hidden">
                            <img className="duration-500 transition-all hover:scale-110" src={item.image} alt="cover" />
                        </div>
                        <h3 className="text-textmin lg:text-description font-bold tracking-tight text-start text-primary-900">{item.title}</h3>
                        <h4>{item.description}</h4>
                        <Button variant="link" className="flex justify-center items-center p-0 gap-x-2 text-white">
                            <p className="text-textlight font-semibold tracking-tight text-start text-secondary-100">Saiba mais</p>
                            <IoIosArrowForward size={15} className="text-secondary-100 mt-1" />
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    )
}  