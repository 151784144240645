import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "src/components/ui/breadcrumb"

import { Button } from "../ui/button"

import SamsungSDS from '../../assets/SamsungSDS.png'
import Idea from '../../assets/idea.png'
import { Link } from "react-router-dom"
import { useEffect } from "react"

export default function IntroBrityRPA() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <body>
            <header>
                <div className="hidden lg:flex bg-secondary-200 items-center w-full justify-between">
                    <div className="relative bg-secondary-200 w-10/12 h-full px-10 py-3">
                        <h3 className="text-textmin font-bold tracking-tight text-white">Brity RPA</h3>
                    </div>
                    <Breadcrumb className="flex bg-secondary-100 text-white w-3/12 h-14 justify-end border-l-[50px] border-l-transparent px-10 py-4" style={{ clipPath: 'polygon(0 100%, 100% -150%, 100% 100%)' }}>
                        <BreadcrumbList>
                            <BreadcrumbItem>
                                <BreadcrumbLink href="/interas">Início</BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator />
                            <BreadcrumbItem>
                                <BreadcrumbLink href="/interas/dev">EasyDev</BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator />
                            <BreadcrumbItem>
                                <BreadcrumbPage>BrityRPA</BreadcrumbPage>
                            </BreadcrumbItem>
                        </BreadcrumbList>
                    </Breadcrumb>
                </div>
                <div className="flex justify-start items-center w-full px-5 lg:px-10 py-5">
                    <img src={SamsungSDS} className="w-6/12 lg:w-[8%]" alt="SamsungSDS" />
                </div>
            </header>
            <div className="flex gap-x-60 w-full h-auto justify-center lg:justify-evenly items-center py-14">
                <div className="flex flex-col w-auto gap-y-8">
                    <h1 className="text-subtitle lg:text-title font-bold tracking-tight text-primary-900">Brity RPA</h1>
                    <h3 className="text-textlight lg:text-description font-light tracking-tight text-start text-primary-900">Automação de processos inteligente</h3>
                    <div className="flex flex-col lg:flex-row gap-y-5 lg:gap-x-5">
                        <Link to="https://w.app/i5ou8n">
                            <Button variant="decorated" className="p-5 font-bold w-full rounded-full border-black hover:border-transparent"><span className="relative z-10">Vamos conversar melhor</span></Button>
                        </Link>
                        <Link to="https://www.samsungsds.com/en/enterprise-asset/gartner-magic-quadrant-RPA2021.html">
                            <Button variant="decorated" className="p-5 font-bold w-full rounded-full border-black hover:border-transparent"><span className="relative z-10">Leia o relatório complementar</span></Button>
                        </Link>
                    </div>
                </div>
                <div className="hidden lg:flex">
                    <img src={Idea} alt="Idea" />
                </div>
            </div>
            <div className="flex bg-gray-100 w-full justify-center items-center py-20">
                <div className="flex flex-col w-full px-5 lg:px-0 lg:w-8/12 justify-center items-center gap-y-5">
                    <h2 className="text-description lg:text-subtitle font-bold tracking-tight text-primary-900">Aumente a produtividade e a qualidade das informações com o Brity RPA, a solução RPA da Samsung SDS</h2>
                    <h4 className="text-textlight font-regular tracking-tight text-primary-900">O Brity RPA automatiza de forma eficiente e flexível os processos manuais e repetitivos, fornecendo
                        tecnologia transacional entre sistemas distintos das empresas, automatizando processos, reduzindo
                        custos e tempo, abrindo espaço para análises estratégicas e novos negócios.
                    </h4>
                </div>
            </div>
        </body>
    )
}