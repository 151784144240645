// @ts-ignore
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'

import { FaChevronDown } from "react-icons/fa"

import { useRef } from 'react'
import { contentCards } from 'src/Utils/useContentCards'
import { CoverInfoSolutions } from 'src/Utils/useCoverInfoSolutions'
import { Button } from '../../ui/button'
import CategoryCards from './CategoryCards'

declare global {
    interface HTMLDivElement {
        splide: any
    }
}

export default function CardSlider() {

    const isMobile = window.innerWidth < 720

    const splideRef = useRef<Splide | null>(null)

    const handleNextSlide = () => {
        const splideInstance = splideRef.current?.splide

        if (splideInstance) {
            splideInstance.go('+1')
        }
    }

    return (
        <div>
            <Splide
                ref={splideRef}
                options={{
                    rewind: true,
                    direction: 'ttb',
                    height: isMobile ? '72rem' : '45rem' ,
                    drag: false,
                    wheel: false,
                    Button: false,
                    interval: 3000,
                    easing: 'cubic-bezier(1, 0.55, 0.30, 0)',
                }}
            >
                {Object.keys(contentCards).map((categoryKey, index) => (
                    <SplideSlide key={`${categoryKey}-${index}`} className="flex flex-col justify-center items-center gap-y-14">
                        <div key={`cover-info-${index}`} className='flex flex-col w-full h-auto justify-center items-center -mb-10'>
                            <img className="w-full h-[140px] lg:w-[70%] lg:h-auto" src={CoverInfoSolutions[index].image} alt="Slide" />
                            <div className="flex flex-col justify-center items-center mt-[-100px] lg:mt-[-160px]">
                                <h1 className="text-light xl:text-description text-white">
                                    {CoverInfoSolutions[index].title}
                                </h1>
                                <h2 className="text-minlight xl:text-light text-white text-center font-extralight">
                                    {CoverInfoSolutions[index].description}
                                </h2>
                            </div>
                        </div>
                        <div className="bg-black bg-opacity-40 rounded-full p-2 lg:mt-[20px] mt-[5px]">
                            <Button onClick={handleNextSlide} variant="secondary">
                                <FaChevronDown size="13px" />
                            </Button>
                        </div>
                        <CategoryCards categoryKey={categoryKey} />
                    </SplideSlide>
                ))}
            </Splide>
        </div>
    )
}