import Contato from "src/components/Contato"
import IntroServerPage from "src/components/ServerPage"
import CloudServices from "src/components/ServerPage/cloud-services"
import CloudExclusiveAdvantages from "src/components/ServerPage/exclusive-advantages"
import CloudSimulation from "src/components/ServerPage/simulation"
import SmoothScroll from "src/components/SmoothScroll"

function ServerPage() {

    return (
        <>
            <SmoothScroll>
                <IntroServerPage />
                <CloudServices />
                <CloudExclusiveAdvantages />
                <CloudSimulation />
                <Contato />
            </SmoothScroll>
        </>
    )
}

export default ServerPage