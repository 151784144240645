import { useEffect, useState } from 'react'
import imageAboutUs from 'src/assets/HomePage/ImageAboutUs.png'
import bgAboutUs from '../../assets/BgAboutUs.png'
import bgAboutUsMobile from '../../assets/BgAboutUsMobile.png'
import { Button } from '../ui/button'

import { handleResize } from 'src/Utils/useHandleResize'
import { CardBody, CardContainer, CardItem } from "../uiCard/3d-card";

export default function AboutUs() {

    const [bgImage, setBgImage] = useState(window.innerWidth < 519 ? bgAboutUsMobile : bgAboutUs)

    useEffect(() => {
        handleResize({
            setBgImage: setBgImage,
            bgDesktop: bgAboutUs,
            bgMobile: bgAboutUsMobile
        })
    }, [])

    const corPersonalizada = "#0061FE"

    return (
        <div id="quemSomos" className="flex flex-col lg:flex-row w-full h-[120vh] justify-evenly items-center transition-all p-5 lg:p-0"
            style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover' }}>
            <div data-aos="fade-up"
                data-aos-duration="1000"
                className="flex justify-center lg:items-end items-center">
                <CardContainer className="inter-var">
                    <CardBody className="relative group/card w-auto xl:w-[28rem] 2xl:w-[40rem] h-auto rounded-xl p-4">
                        <CardItem translateZ="100" className="w-full mt-4">
                            <img src={imageAboutUs} alt="" />
                        </CardItem>
                    </CardBody>
                </CardContainer>
            </div>
            <div className="flex w-full xl:w-4/12 justify-evenly xl:justify-center items-center xl:items-start flex-col h-full xl:gap-6">
                <h1 className="text-secondy xl:text-secondy 2xl:text-subtitle text-center xl:text-start leading-[70px] font-bold text-white">
                    Quem somos?
                </h1>
                <h2  className="text-min xl:text-min 2xl:text-description font-semibold text-center lg:text-start text-white">
                    Tecnologia para Gestão <span style={{ color: corPersonalizada }}>e Desenvolvimento</span>
                </h2>
                <h4 className="text-minlight xl:text-light text-center lg:text-start text-white">
                    Somos uma empresa integradora de soluções e consultoria em Tecnologia.
                    Unimos novas ideias à novas tecnologias e oferecemos um olhar único para sua necessidade tecnológica.
                </h4>
                <a href="#services" className='flex w-full justify-center xl:justify-start text-white'>
                    <Button variant="default" size="sm" className="w-full xl:w-auto font-semibold text-textmin">Nossos serviços</Button>
                </a>
            </div>
        </div>
    )
}