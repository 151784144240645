interface Props {
    id: string
    setShowButton: (value: boolean) => void
}

export const AriseButton = ({ setShowButton, id }: Props) => {
    const AriseButtonIdScreen = () => {
        const scrollY = window.scrollY
        const servicesSection = document.getElementById(id)
        if (servicesSection && scrollY >= servicesSection.offsetTop) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }
    }

    window.addEventListener('scroll', AriseButtonIdScreen)
    return () => window.removeEventListener('scroll', AriseButtonIdScreen)
}