import SmoothScroll from "src/components/SmoothScroll"
import Contato from "../../components/Contato"
import IntroDevPage from "../../components/DevPage"
import DevAplication from "../../components/DevPage/dev-aplication"
import DevRpa from "../../components/DevPage/dev-rpa"
import DevServices from "../../components/DevPage/dev-service"

function DevPage() {

    return (
        <>
            <SmoothScroll>
                <IntroDevPage />
                <DevAplication />
                <DevRpa />
                <DevServices />
                <Contato />
            </SmoothScroll>
        </>
    )
}

export default DevPage