import { Link } from "react-router-dom";
import {
    DropdownMenuContent,
    DropdownMenuItem,
} from "../ui/dropdown-menu"

import Logo from '../../assets/LogoInteras.png'
import Triangle from '../../assets/Triangule.svg'

import { Products } from "src/Utils/useProductsLinks";
import { Button } from "../ui/button";

interface Props {
    handleIsOpenMenu: () => void
}

export default function LinksMenu({handleIsOpenMenu}: Props) {


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        handleIsOpenMenu()
    }

    return (
        <DropdownMenuContent className="flex flex-col w-8/12 bg-primary-900 p-4 rounded-[5px] border-none mt-10">
            <div className="absolute top-5 right-[510px]">
                <img src={Triangle} alt="" />
            </div>
            <div className="flex w-full h-auto justify-between items-start gap-x-4 py-5">
                <div className="flex flex-col w-5/12 h-80 bg-primary-800 justify-between items-start rounded-xl px-4 py-4 gap-y-8">
                    <img src={Logo} width="75%" alt="" />
                    <div className="flex flex-col w-full h-auto justify-center items-center gap-y-8">
                        <p className=' text-white text-start text-minlight font-extralight'>
                            Tecnologia para Gestão e Desenvolvimento Copyright©2024.
                        </p>
                        <p className="text-white text-start text-minlight font-extralight">
                            Interas Tecnologia.Todos os direitos reservados.
                        </p>
                    </div>
                    <div className="flex w-full h-auto justify-center items-center">
                        <Button
                            variant="Submit"
                            className="w-full text-minlight text-white font-semibold">
                            Entre em contato
                        </Button>
                    </div>
                </div>
                <div className="flex flex-col w-8/12 gap-y-0">
                    {Products.map((item, index) => (
                        <DropdownMenuItem key={index}>
                            <div className="flex flex-col w-full justify-center items-center">
                                <Link to={item.href} onClick={scrollToTop}>
                                    <div className="flex flex-col bg-primary-800 justify-start items-start px-2 py-4 rounded-xl">
                                        <div className="flex w-auto justify-center items-start gap-x-2">
                                            <div className="flex border-2 border-white/10 rounded-full p-2">
                                                <item.icon
                                                    className="h-auto w-5 text-white hover:text-blue-500 transition-all"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="flex flex-col w-auto justify-start items-start gap-y-2">
                                                <h2 className=" text-textmin font-bold tracking-tight text-white hover:text-blue-500 transition-all">{item.name}</h2>
                                                <h4 className="text-white hover:text-blue-500 transition-all">{item.description}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </DropdownMenuItem>
                    ))}
                </div>
            </div>
        </DropdownMenuContent>
    )
}