export default function Characteristics() {

    const cardsText = [
        {
            title: 'Inteligência Artificial',
            description: 'Solução Brity RPA oferece tecnologia inovadora Samsung SDS de fácil aplicação e rápida implantação, reduzindo custos em operações manuais repetitivas por meio da automação das operações e inteligência artificial.',
        },
        {
            title: 'Inteligência Artificial Visual',
            description: 'Execução de atividades ou inspeção de produção por meio da identificação visual de elementos ou situações nas imagens capturadas.',
        },
        {
            title: 'Chat Bot & STT (Speech to Text)',
            description: 'Permite a interação NLP (Natural Language Process) via chat bot ou interface personalizada para a tomada de decisão e execução de processos.',
        },
    ]

    return (
        <div className="flex flex-col w-full justify-start items-center py-20 gap-y-3">
            <div className="flex w-10/12 justify-start px-0 lg:px-9">
                <p className="text-textmin lg:text-description font-semibold tracking-tight text-start text-primary-900">Características</p>
            </div>
            <div className="flex flex-col lg:flex-row w-full justify-center items-center px-5 lg:px-0 gap-y-10 lg:gap-y-0 gap-x-8">
                {cardsText.map((item, index) => (
                    <div key={index} className="flex flex-col w-full lg:w-3/12 h-80 bg-gray-100 justify-start items-start gap-y-5 px-16 py-10">
                        <h2 className="text-textlight lg:text-description font-bold tracking-tight text-start text-primary-900">{item.title}</h2>
                        <h4 className="text-texlight font-regular tracking-tight text-start text-primary-900">{item.description}</h4>
                    </div>
                ))}
            </div>
        </div>
    )
}