import { Button } from "../ui/button";
import AppImage from "../../assets/apps.webp";

export default function DevAplication() {
    return (
        <div className="h-full flex flex-col overflow-hidden lg:flex-row bg-primary-900 items-center py-0 lg:py-16">
            {/* Image side */}
            <div
                className="w-full lg:w-1/2 pt-16 lg:pt-0 h-auto flex justify-center items-end"
                data-aos="fade-up-left"
            >
                <img className="w-auto h-80 py-8 lg:py-0 rounded-2xl animate-[bounce_2s_ease-in-out_infinite]" src={AppImage} />
            </div>
            {/* Text side */}
            <div
                className="w-full lg:w-1/2 px-8 lg:px-0  pb-8 lg:pb-0 space-y-6 text-center lg:text-start "
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
            >
                <h1 className="text-4xl lg:text-6xl font-bold tracking-tight text-white">
                    Desenvolvimento <br /> de Aplicações
                </h1>
                <h1 className="text-2xl lg:text-4xl font-thin tracking-tight text-white">
                    Construa o app
                    <span className="text-secondary-100 text-2xl lg:text-4xl font-thin">
                        ideal para <br /> sua empresa
                    </span>
                </h1>
                <p className="text-white text-justify font-thin w-full lg:w-9/12 text-lg lg:text-base">
                    Por meio de um app, uma empresa pode ter um espaço para tirar dúvidas
                    e conhecer as ideias dos clientes para tornar o negócio mais atrativo.
                    Tão importante quanto ter um canal para interagir com os stakeholders
                    é fornecer um feedback das mensagens enviadas pelos clientes por meio
                    do aplicativo.
                </p>
                <a href="#contato">
                    <Button variant="default" className="font-semibold text-sm w-50 mt-5">
                        Quero adquirir
                    </Button>
                </a>
            </div>
        </div>
    );
}
