import { CiShare1 } from "react-icons/ci";
import { TfiDownload } from "react-icons/tfi";
import { Link } from "react-router-dom";

export default function Resources() {
    return (
        <div className="flex flex-col w-full h-auto bg-gray-100 justify-center items-center py-20">
            <div className="flex flex-col w-8/12 border-b-2 border-black gap-y-3 py-5">
                <p className="text-textmin lg:text-description font-semibold tracking-tight text-start text-primary-900">Recursos</p>
                <h2 className="text-description lg:text-subtitle font-semibold tracking-tight text-start text-primary-900">Leia, baixe e compartilhe</h2>
            </div>
            <div className="flex w-8/12 border-b-2 border-gray-200 items-center justify-between gap-y-3 py-5">
                <div>
                    <p className="text-textlight font-regular tracking-tight text-start text-gray-500">White Paper</p>
                    <h2 className="text-textmin lg:text-description font-semibold tracking-tight text-start text-primary-900">Your Solution to Logistics Process Automation, Brity RPA</h2>
                </div>
                <Link to="https://www.samsungsds.com/en/enterprise-asset/logistics-brityworks-wp-en.html">
                    <CiShare1 className="size-8 hover:text-secondary-100" />
                </Link>
            </div>
            <div className="flex w-8/12 border-b-2 border-gray-200 items-center justify-between gap-y-3 py-5">
                <div>
                    <p className="text-textlight font-regular tracking-tight text-start text-gray-500">Brochure</p>
                    <h2 className="text-textmin lg:text-description font-semibold tracking-tight text-start text-primary-900">Brity RPA at a glance</h2>
                </div>
                <Link to="https://image.samsungsds.com/en/resources/__icsFiles/afieldfile/2018/08/21/Samsung_SDS_Brity_Works_Eng_Web_Two_Page_View_180809.pdf">
                    <TfiDownload className="size-8 hover:text-secondary-100" />
                </Link>
            </div>
        </div>
    )
}