import { useEffect, useState } from 'react'

import AnalysisRobo from '../../assets/RoboAnalise.png'
import AnalysisRoboMobile from '../../assets/RoboAnaliseMobile.png'

import { Button } from '../ui/button'

export default function AnalysisReports() {

    const [bgImage, setBgImage] = useState(window.innerWidth < 519 ? AnalysisRoboMobile : AnalysisRobo)

    useEffect(() => {
      const handleResize = () => {
        const isMobile = window.innerWidth < 519
        setBgImage(isMobile ? AnalysisRoboMobile : AnalysisRobo)
      }
  
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <div className="flex flex-col bg-gray-100 justify-center items-center gap-y-5 py-20">
            <div className="flex w-full lg:w-9/12 px-5 justify-start">
                <p className="text-textmin lg:text-description font-semibold tracking-tight text-start text-primary-900">Relatórios de análise</p>
            </div>
            <div
                className='flex w-full lg:w-9/12 h-auto justify-end items-end py-16'
                style={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '80vh'
                }}
            >
                <div className='flex flex-col w-11/12 lg:w-9/12 h-[40vh] bg-gray-100 justify-start items-start py-10 lg:py-20 px-8 lg:px-20 gap-y-5'>
                    <h2 className="text-textlight lg:text-description font-bold tracking-tight text-start text-primary-900">in Magic Quadrant for Robotic Process Automation, July 2021</h2>
                    <div>
                        <h4 className="text-texlight font-regular tracking-tight text-start text-primary-900">in Magic Quadrant for Robotic Process Automation, July 2021, Gartner</h4>
                        <Button variant="link" className='p-0'>*Disclaimer</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}