interface Props {
    setBgImage: (value: string) => void;
    bgDesktop: string;
    bgMobile: string;
}

export const handleResize = ({
    setBgImage,
    bgDesktop,
    bgMobile
}: Props) => {
    const updateBackground = () => {
        const isMobile = window.innerWidth < 519;
        setBgImage(isMobile ? bgMobile : bgDesktop);
    };

    window.addEventListener('resize', updateBackground);

    return () => window.removeEventListener('resize', updateBackground);
};