// @ts-ignore
import DashIcon from "../../assets/dashIcon.png";
import MoneyIcon from "../../assets/moneyIcon.png";
import ShieldIcon from "../../assets/shieldIcon.png";
import { useEffect, useState } from "react";
import BackgrounAdvantages from "../../assets/VantagensExclusivasBg.png";
import BackgrounAdvantagesMobile from "../../assets/MobilaVantagenBg.png";

const advantagesList = [
  {
    iconImage: DashIcon,
    altImage: "Dashboard Icon",
    title: "Agilidade",
    description:
      "Possibilidade de veicular informações de qualquer lugar e ter acesso a programas, aplicações e dados de diferentes dispositivos. Tenha mais autonomia dentro da sua empresa e expanda seu raio de atuação!",
  },
  {
    iconImage: MoneyIcon,
    altImage: "Money Icon",
    title: "Agilidade",
    description:
      "Redução de custos de armazenamento em comparação com infraestruturas físicas. Economize muito em logística e infraestrutura com as soluções da Interas. Hospede de maneira inteligente e personalizada para você!",
  },
  {
    iconImage: ShieldIcon,
    altImage: "Shield Icon",
    title: "Agilidade",
    description:
      "Garantia de ter um sistema de segurança permanentemente atualizado. Não seja pego de surpresa, evite danos catastróficos para sua empresa. Tenha na palma das mãos um dos sistemas de armazenamento mais seguros do mundo!",
  },
];

export default function CloudExclusiveAdvantages() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div id="cloud-exclusive-advantages" className="h-full">
      <div
        className="h-auto lg:my-8 p-10 lg:mx-32 lg:rounded-3xl"
        style={{
          backgroundImage: `url(${isMobile ? BackgrounAdvantagesMobile : BackgrounAdvantages
            })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: isMobile ? "auto" : "100vh",
        }}
      >
        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          className="w-full h-auto justify-center text-center"
        >
          <h1 className="text-subtitle lg:text-title justify-center items-center font-bold text-white">
            Vantagens exclusivas
          </h1>
          <h2 className="text-description justify-center items-start  text-white">
            Utilizando um data base
          </h2>
        </div>

        <div
          className="flex flex-col lg:flex-row h-auto mt-40 lg:px-20 px-0 lg:gap-10 gap-y-10 text-white lg:py-0"
          data-aos="fade-right"
          data-aos-duration="1500"
        >
          {advantagesList.map((advantage) => {
            return (
              <div className="flex flex-col lg:justify-start justify-center lg:items-start items-center">
                <div className="flex">
                  <img
                    className="rounded-t-lg h-14 w-auto"
                    src={advantage.iconImage}
                    alt={advantage.altImage}
                  />
                  <h2 className="text-subtitle">{advantage.title}</h2>
                </div>
                <p className="textmin text-justify">{advantage.description}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}
