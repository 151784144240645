import ReactJs from '../assets/HomePage/react.png'
import NodeJs from '../assets/HomePage/node.png'
import NextJs from '../assets/HomePage/next.png'

import Monitoramento from '../assets/HomePage/monitoramento.png'
import Servidores from '../assets/HomePage/servidores.png'
import Mikrotik from '../assets/HomePage/Mikrotik.png'
import Firewall from '../assets/HomePage/firewall.png'

import Camera from '../assets/HomePage/Camera.png'
import Cancelas from '../assets/HomePage/Catraca.png'
import Cabeamentos from '../assets/HomePage/cabeamentos.png'
import Mooving from '../assets/HomePage/mooving.png'

interface Card {
    image: string;
    title: string;
    description: string;
    link?: string;
}

interface ContentCards {
    CardsDev: Card[];
    CardsSupport: Card[];
    CardsInfra: Card[];
}

export const contentCards: ContentCards = {

    CardsDev: [
        {
            image: ReactJs,
            title: 'React Js',
            description: 'O React.js é usado para desenvolver interfaces de usuário interativas e eficientes, oferecendo uma abordagem modular e declarativa para aplicações web.',
            link: 'https://pt-br.legacy.reactjs.org/',
        },
        {
            image: ReactJs,
            title: 'React Native',
            description: 'O React Native é utilizado para criar aplicativos móveis nativos para iOS e Android, compartilhando grande parte do código entre as plataformas e assim trazendo praticidade ao desenvolver.',
            link: 'https://pt-br.legacy.reactjs.org/',
        },
        {
            image: NodeJs,
            title: 'Node Js',
            description: 'O Node.js é uma plataforma de execução de código JavaScript do lado do servidor, onde permite o desenvolvimento eficiente de aplicativos escaláveis em tempo real.',
            link: 'https://nodejs.org/docs/latest/api/',
        },
        {
            image: NextJs,
            title: 'Next Js',
            description: 'O Next.js é um framework React que simplifica a construção de aplicativos web, fornecendo roteamento intuitivo, renderização do lado do servidor e uma experiência de desenvolvimento eficiente.',
            link: 'https://nextjs.org/docs',
        },
    ],

    CardsSupport: [
        {
            image: Monitoramento,
            title: 'Monitoramento',
            description: 'Soluções robustas como Grafana e Zabbix para supervisionar e otimizar suas operações online, proporcionando insights precisos e em tempo real.',
        },
        {
            image: Servidores,
            title: 'Servidores',
            description: 'Especialistas na configuração de servidores Windows e Linux, assegurando desempenho e segurança ideais para suportar suas demandas tecnológicas.',
        },
        {
            image: Mikrotik,
            title: 'Mikrotik',
            description: 'Nossa equipe de especialistas oferece consultoria personalizada para aprimorar sua infraestrutura de rede, destacando-se na eficiência e na confiabilidade.',
        },
        {
            image: Firewall,
            title: 'Firewalls',
            description: 'Configuramos e mantemos firewalls de última geração para garantir a segurança contínua dos seus sistemas, proporcionando tranquilidade e proteção robusta.',
        },
    ],

    CardsInfra: [
        {
            image: Camera,
            title: 'Camera',
            description: 'Instalamos sistemas de câmeras de alta tecnologia para garantir a segurança, proporcionando monitoramento eficiente em tempo real e prevenção proativa de incidentes.',
        },
        {
            image: Cancelas,
            title: 'Cancelas',
            description: 'Implementamos sistemas de cancelas e catracas modernas para um controle de acesso eficiente, promovendo a segurança e proteção das instalações contra acessos não autorizados.',
        },
        {
            image: Cabeamentos,
            title: 'Cabeamentos',
            description: 'Realizamos instalações completas de infraestrutura seca, incluindo cabeamento e redes Wi-Fi de última geração, garantindo uma conectividade eficiente para suportar as demandas do ambiente.',
        },
        {
            image: Mooving,
            title: 'Mooving',
            description: 'Executamos com precisão o moving de racks, assegurando a integridade e a continuidade operacional durante mudanças físicas na infraestrutura, mantendo a eficiência e a disponibilidade dos sistemas.',
        },
    ]
}