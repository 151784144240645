import Whatsapp from 'src/assets/WhatsBlue.svg'
import { Instagram, Linkedin, Mail, MapPin } from 'lucide-react';
import { Link } from "react-router-dom";

export default function LinksFooter() {
    return (
        <div className='flex flex-col lg:flex-row justify-start items-start gap-y-4 gap-x-10'>
            <div className='flex flex-col w-full lg:w-8/12 justify-center items-center text-center lg:text-start lg:items-start text-white gap-y-4'>
                <p className='text-textmin font-semibold'>Informações</p>
                <div className='flex w-full justify-center items-center gap-x-2'>
                    <Link className='flex flex-col lg:flex-row w-full items-center lg:items-start gap-x-2 hover:underline' to="/">
                        <img src={Whatsapp} width="6%" alt="Whatsapp" />
                        <p>+55 84 4042-0130</p>
                    </Link>
                </div>
                <div className='flex w-full justify-center items-center gap-x-2'>
                    <Link className='flex flex-col lg:flex-row w-full items-center lg:items-start gap-x-2 hover:underline' to="/">
                        <Mail size="25px" color="#0061FE" />
                        <p>administração@interas.com.br</p>
                    </Link>
                </div>
                <div className='flex w-full justify-center items-center gap-x-2'>
                    <Link className='flex flex-col lg:flex-row w-full items-center lg:items-start gap-x-2 hover:underline' to="/">
                        <MapPin size="32px" color="#0061FE" />
                        <p>Natal - RN R. Coronel José Guimarães, 151 Lagoa Nova - 59054-795</p>
                    </Link>
                </div>
            </div>
            <div className='flex flex-col w-full lg:w-6/12 text-white justify-center items-center gap-y-4'>
                <p className='text-textmin font-semibold'>Links</p>
                <div className='flex lg:flex-col w-full justify-center items-center gap-x-5 gap-y-3 lg:gap-x-0'>
                    <Link className='hover:underline' to="/">Início</Link>
                    <Link className='hover:underline' to="/">Quem Somos</Link>
                    <Link className='hover:underline' to="/">Parceiros</Link>
                </div>
            </div>
            <div className='flex flex-col w-full lg:w-6/12 justify-center items-center text-center lg:text-start lg:items-start text-white gap-y-4'>
                <p className='text-textmin font-semibold'>Redes Sociais</p>
                <div className='flex w-full justify-center items-center'>
                    <Link className='flex flex-col lg:flex-row w-full items-center lg:items-start gap-x-2 hover:underline' to="/">
                        <Instagram size="28px" color="#0061FE" />
                        <p>Instagram Interas Tecnologia</p>
                    </Link>
                </div>
                <div className='flex w-full justify-center items-center'>
                    <Link className='flex flex-col lg:flex-row w-full items-center lg:items-start gap-x-2 hover:underline' to="/">
                        <Linkedin size="28px" color="#0061FE" />
                        <p>LinkedIn Interas Tecnologia</p>
                    </Link>
                </div>
            </div>
        </div>
    )
}