import { CardBody, CardContainer, CardItem } from "../../uiCard/3d-card";

import Sticker from 'src/assets/HomePage/Sticker.png'

export function ThreeDCardDemo() {
    return (
        <CardContainer className="inter-var">
            <CardBody className="relative group/card w-auto xl:w-[32rem] 2xl:w-[52rem] h-auto rounded-xl p-4">
                <CardItem translateZ="100" className="w-full mt-4">
                    <img
                        data-aos="fade-left"
                        data-aos-duration="900"
                        data-aos-delay="800"
                        src={Sticker} alt="" />
                </CardItem>
            </CardBody>
        </CardContainer>
    );
}
