import { Link } from 'react-router-dom'
import { servicesCards } from 'src/Utils/useServicesCards'
import { Button } from 'src/components/ui/button'

export default function Services() {
    return (
        <div id='services' className="flex flex-col w-full h-auto xl:h-[140vh] justify-center items-center gap-10">
            <div className="flex flex-col w-full justify-center items-center gap-5 p-3">
                <h1 data-aos="fade-up" data-aos-duration="1000" className="text-subtitle xl:text-secondy 2xl:text-title hidden lg:block font-bold text-primary-800">
                    Serviços para você
                </h1>
                <h3 data-aos="fade-up" data-aos-duration="1500" className="textlight xl:text-minlight 2xl:text-light text-secondary-100 font-light text-center">
                    Soluções de alta performace para seu negócio, consultoria<br />tecnológica desde a pesquisa à execução. Garantia de sucesso ao seu<br />projeto!
                </h3>
            </div>
            <div className="flex flex-col xl:flex-row w-full justify-center items-center gap-5">
                {servicesCards.map((item, index) => (
                    <div
                        data-aos="fade-zoom-in"
                        data-aos-easing="ease-in-back"
                        data-aos-delay="500"
                        data-aos-offset="0"
                        className="flex flex-col w-10/12 xl:w-3/12 bg-white border border-gray-200 rounded-2xl shadow dark:bg-gray-800 dark:border-gray-700"
                        key={index}
                    >
                        <a href="/">
                            <img className="rounded-t-lg" src={item.image} alt="" />
                        </a>
                        <div className="p-5">
                            <a href="/">
                                <h5 className="mb-2 text-description font-bold tracking-tight text-gray-900 dark:text-white">{item.title}</h5>
                            </a>
                            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 text-justify">
                                {item.description}
                            </p>
                            <Link to={item.link}><Button variant="destructive">Saiba mais</Button></Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}