import bgSolution from 'src/assets/HomePage/bgSolutions.png'
import Card from "./card"

import seta from 'src/assets/HomePage/seta.svg'

export default function Solutions() {
    return (
        <div className="flex lg:flex-row flex-col justify-center items-center w-full h-full transition-all py-10 lg:p-0 gap-10"
            style={{ backgroundImage: `url(${bgSolution})`, backgroundSize: 'cover' }}
        >
            <div className="flex flex-col justify-center items-center overflow-hidden w-full h-full">
                <div className='flex flex-col lg:w-3/6 w-full justify-center lg:items-start items-center gap-3'>
                    <h1 className='text-description xl:text-secondy 2xl:text-subtitle lg:text-title font-bold text-white'>Como te ajudamos?</h1>
                    <div className="flex justify-center items-center gap-3">
                        <h2 className='text-min xl:text-min 2xl:text-description font-extralight text-white'>Como desenvolvemos?</h2>
                        <img src={seta} alt="icon" className='hidden lg:block' />
                    </div>
                </div>
                <Card />
            </div>
        </div>
    )
}